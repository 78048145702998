import React from "react";
import Layout from "../components/organisms/Layout";
import DynamicComponent from "../components/molecules/DynamicComponent";

import Storyblok, { useStoryblok } from "../utils/storyblok";

export default function Home({ story, preview, children, alldata }) {
    const enableBridge = true; // load the storyblok bridge everywhere
    // use the preview variable to enable the bridge only in preview mode
    // const enableBridge = preview;
    story = useStoryblok(story, enableBridge);

    return (
        <>
            <Layout >
                <DynamicComponent blok={story.content} alldata={alldata} />
            </Layout>
        </>
    );
}

export async function getStaticProps({ params, preview = false }) {
    let slug = "/home";
    let sbParams = {
        version: "draft", // or 'draft',
        resolve_relations: ["ShopGrid.items", "Landing.infos", "Product.type", "Product.category"],
    };

    if (preview) {
        sbParams.version = "draft";
        sbParams.cv = Date.now();
    }

    let { data } = await Storyblok.get(`cdn/stories/${slug}`, sbParams);

    return {
        props: {
            story: data ? data.story : false,
            alldata: data ? data : null,
            preview,
        },
        revalidate: 10, // revalidate every hour
    };
}
